import { Flex, Spinner } from "@legion-ui/core";

function PageLoading() {
  return (
    <Flex as="main" height="100vh" alignX="center" alignY="center">
      <Spinner size="lg" />
    </Flex>
  );
}

export default PageLoading;
