import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const ProtectedRoute = (props) => {
  const token = secureLocalStorage.getItem("token");

  if (token === null) {
    return <Navigate to="/login" replace />;
  }

  return props.children;
};

export default ProtectedRoute;
