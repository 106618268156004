import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "../apiConfig";

const LoginRoute = (props) => {
  const token = secureLocalStorage.getItem("token");

  const navigate = useNavigate();

  if (token !== null) {
    axios
      .get("verify-token")
      .then((res) => {
        return navigate("/");
      })
      .catch((err) => {
        return props.children;
      });
  } else {
    return props.children;
  }
};

export default LoginRoute;
