export const TokensBigBox = {
  primary25: "#f5f8fd",
  primary50: "#e1e9f9",
  primary100: "#cddbf5",
  primary200: "#acc2ef",
  primary300: "#648ee1",
  primary400: "#4376db",
  primary500: "#0549cf",
  primary600: "#043dac",
  primary700: "#002e84",
  primary800: "#022567",
  primary900: "#00153c",
  secondary25: "#fff0f0",
  secondary50: "#ffd9db",
  secondary100: "#ffb4b6",
  secondary200: "#ff8e92",
  secondary300: "#ff686e",
  secondary400: "#ff4349",
  secondary500: "#ff1d25",
  secondary600: "#d90209",
  secondary700: "#aa1319",
  secondary800: "#870000",
  secondary900: "#550a0c",
  tertiary25: "#ffffff",
  tertiary50: "#f6f6f6",
  tertiary100: "#efefef",
  tertiary200: "#e4e4e4",
  tertiary300: "#c9c9c9",
  tertiary400: "#aaaaaa",
  tertiary500: "#858585",
  tertiary600: "#737373",
  tertiary700: "#565656",
  tertiary800: "#1e1e1e",
  tertiary900: "#000000",
  error25: "#fff8f8",
  error50: "#fff0f0",
  error100: "#fddbda",
  error200: "#fcc3c1",
  error300: "#f9928f",
  error400: "#f76e6a",
  error500: "#f54a45",
  error600: "#cc3e39",
  error700: "#af1631",
  error800: "#7b2522",
  error900: "#31170e",
  warning25: "#fffbf6",
  warning50: "#fff2e4",
  warning100: "#ffe9d2",
  warning200: "#ffdbb4",
  warning300: "#ffbc73",
  warning400: "#ffae56",
  warning500: "#ff931e",
  warning600: "#d57b19",
  warning700: "#a66218",
  warning800: "#80490f",
  warning900: "#582d00",
  success25: "#f8fffe",
  success50: "#f0fffd",
  success100: "#cdece8",
  success200: "#abdfd8",
  success300: "#68c6b9",
  success400: "#35b3a2",
  success500: "#03a08b",
  success600: "#028574",
  success700: "#026053",
  success800: "#015045",
  success900: "#01201c",
  information25: "#f2f4fc",
  information50: "#f5faff",
  information100: "#ebf6ff",
  information200: "#b8dffe",
  information300: "#86c8fe",
  information400: "#53b1fd",
  information500: "#219afd",
  information600: "#0281e8",
  information700: "#0265b6",
  information800: "#014983",
  information900: "#012d51",
  typographyHeading: "nunito sans",
  typographyBody: "nunito sans",
  radius1: "2",
  radius2: "4",
  radius3: "6",
  radius4: "8",
  radius5: "12",
  radius6: "16",
  radius7: "24",
  radius8: "500",
  anchorDefaultColor: "#ff1d25",
  anchorHoverColor: "#d90209",
  anchorActiveColor: "#aa1319",
  anchorFocusColor: "#ff1d25",
  anchorDisableColor: "#aaaaaa",
  badgePrimaryBackgroundColor: "#0549cf",
  badgePrimaryColor: "#ffffff",
  badgeSecondaryBackgroundColor: "#e1e9f9",
  badgeSecondaryColor: "#043dac",
  badgeErrorBackgroundColor: "#fff0f0",
  badgeErrorColor: "#cc3e39",
  badgeInfoBackgroundColor: "#f5faff",
  badgeInfoColor: "#0281e8",
  badgeSuccessBackgroundColor: "#f0fffd",
  badgeSuccessColor: "#028574",
  badgeWarningBackgroundColor: "#fff2e4",
  badgeWarningColor: "#d57b19",
  badgeBorderRadius: "6",
  buttonSolidDefaultBackgroundColor: "#0549cf",
  buttonSolidDefaultColor: "#ffffff",
  buttonSolidHoverBackgroundColor: "#043dac",
  buttonSolidHoverColor: "#ffffff",
  buttonSolidActiveBackgroundColor: "#002e84",
  buttonSolidActiveColor: "#ffffff",
  buttonSolidFocusBackgroundColors: "#0549cf",
  buttonSolidFocusColor: "#ffffff",
  buttonSolidFocusBorderColors: "#4376db",
  buttonSolidDisabledBackgroundColor: "#c9c9c9",
  buttonSolidDisabledColor: "#858585",
  buttonSoftDefaultBackgroundColor: "#e1e9f9",
  buttonSoftDefaultColor: "#0549cf",
  buttonSoftHoverBackgroundColor: "#acc2ef",
  buttonSoftHoverColor: "#043dac",
  buttonSoftActiveBackgroundColor: "#648ee1",
  buttonSoftActiveColor: "#002e84",
  buttonSoftFocusBackgroundColor: "#e1e9f9",
  buttonSoftFocusColor: "#0549cf",
  buttonSoftFocusBorderColors: "#4376db",
  buttonSoftDisabledBackgroundColor: "#e4e4e4",
  buttonSoftDisabledColor: "#858585",
  buttonBorderRadius: "6",
  buttonOutlineDefaultBackgroundColor: "#e1e9f9",
  buttonOutlineDefaultColor: "#0549cf",
  buttonOutlineHoverBackgroundColor: "#acc2ef",
  buttonOutlineHoverColor: "#043dac",
  buttonOutlineActiveBackgroundColor: "#648ee1",
  buttonOutlineActiveColor: "#002e84",
  buttonOutlineFocusBackgroundColor: "#e1e9f9",
  buttonOutlineFocusColor: "#0549cf",
  buttonOutlineFocusBorderColors: "#4376db",
  buttonOutlineDisabledBackgroundColor: "#e4e4e4",
  buttonOutlineDisabledColor: "#858585",
  buttonTransparentDefaultBackgroundColor: "#e1e9f9",
  buttonTransparentDefaultColor: "#0549cf",
  buttonTransparentHoverBackgroundColor: "#acc2ef",
  buttonTransparentHoverColor: "#043dac",
  buttonTransparentActiveBackgroundColor: "#648ee1",
  buttonTransparentActiveColor: "#002e84",
  buttonTransparentFocusBackgroundColor: "#e1e9f9",
  buttonTransparentFocusColor: "#0549cf",
  buttonTransparentFocusBorderColors: "#4376db",
  buttonTransparentDisabledBackgroundColor: "#e4e4e4",
  buttonTransparentDisabledColor: "#858585",
  checkboxDefaultBackgroundColor: "#ffffff",
  checkboxDefaultBorder: "#aaaaaa",
  checkboxDisabledBackgroundColor: "#efefef",
  checkboxDisabledBorder: "#c9c9c9",
  checkboxActivedisableBackgroundColor: "#acc2ef",
  checkboxActivedisableColor: "#ffffff",
  checkboxActivedisableBorder: "#0549cf",
  checkboxActiveBackgroundColor: "#0549cf",
  checkboxActiveColor: "#ffffff",
  checkboxActiveBorder: "#acc2ef",
  checkboxBorderRadius: "6",
  fieldDefaultBackgroundColor: "#ffffff",
  fieldDefaultOutlineColor: "#c9c9c9",
  fieldDefaultIconColor: "#1e1e1e",
  fieldDefaultPlaceholderColor: "#858585",
  fieldDefaultLabelColor: "#1e1e1e",
  fieldActiveOutlineColor: "#0549cf",
  fieldActivePlaceholderColor: "#1e1e1e",
  fieldActiveBorderColor: "#cddbf5",
  fieldErrorOutlineColor: "#f54a45",
  fieldSuccessOutlineColor: "#03a08b",
  fieldDisableBackgroundColor: "#efefef",
  fieldDisableOutlineColor: "#c9c9c9",
  fieldDisablePlaceholderColor: "#aaaaaa",
  fieldPrefixsufixLineColor: "#c9c9c9",
  fieldPrefixsufixTextColor: "#1e1e1e",
  fieldBorderRadius: "6",
  fieldDefaultselectColor: "#737373",
  fieldActiveselectBackgroundColor: "#e1e9f9",
  fieldActiveselectColor: "#0549cf",
  radioDefaultBackgroundColor: "#ffffff",
  radioDefaultBorder: "#c9c9c9",
  radioDisableBackgroundColor: "#efefef",
  radioDisableBorder: "#c9c9c9",
  radioActiveBackgroundColor: "#ffffff",
  radioActiveBorder: "#0549cf",
  radioActivedisableBackgroundColor: "#ffffff",
  radioActivedisableBorder: "#648ee1",
  switchActiveBackgroundColor: "#03a08b",
  switchActivedisableBackgroundColor: "#cdece8",
  switchNonactiveBackgroundColor: "#aaaaaa",
  switchNonactivedisableBackgroundColor: "#e4e4e4",
  sidebarDefaultColor: "#0549cf",
  sidebarDefaultBackgroundColor: "#ffffff",
  sidebarDefaultIconColor: "#aaaaaa",
  sidebarHoverBackgroundColor: "#cddbf5",
  sidebarHoverColor: "#0549cf",
  sidebarActiveBackgroundColor: "#E7F2FF",
  sidebarActiveColor: "#007EBC",
  basewhite: "#ffffff",
};
