import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./style.scss";
import App from "./pages/App";

import { ThemeProvider } from "@emotion/react";
// import { createTokens } from "@legion-ui/core";
import { TokensBigBox } from "./config";

// const TokensBigBox = createTokens({
//   apiTokens: "https://api.jsonbin.io/v3/b/645285f99d312622a356c53b",
//   apiKey: "$2b$10$7FAyzBJd8OKEM5Yo4/E9yuJopB4tx14MfUNEE9k/w/sMgwFtGZhFW",
//   tokensName: "bigboxTokens",
// });

// console.log("TokensBigBox", TokensBigBox);

const themeBigBox = {
  name: "BigBox",
  tokens: TokensBigBox,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={themeBigBox}>
    <App />
  </ThemeProvider>
);
