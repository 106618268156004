import React from "react";
import "./style.scss";

function Input({label,...rest}){
  return (
    <>
      <label htmlFor="input-form" className="form-label">
        {label}
      </label>
      <div className="input-wrapper">
        <input
          className="form-control"
          id="input-form"
          {...rest}
        />
      </div>
    </>
  );
}

export default Input;
